export const notifyMixin = {
  methods: {
    showErrorMessage(message, title = 'Ошибка') {
      this.$notify.error({
        title,
        message,
        duration: 1500,
      });
    },
    showSuccessMessage(message, title = 'Успех') {
      this.$notify({
        title,
        message,
        duration: 1500,
        type: 'success',
      });
    },
  },
};

<template>
  <el-form
    v-if="show"
    ref="ingredient-semifished"
    :model="ingredientDish"
    label-position="top"
    class="mb-4"
    @submit.prevent.native="saveFn"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-autocomplete
          v-model="ingredientDish.ingredient.title"
          prefix-icon="el-icon-search"
          style="width: 100%"
          :fetch-suggestions="searchIngredientsFn"
          :debounce="600"
          placeholder="Найти ингредиент"
          value-key="title"
          @select="selectIngredientFn"
        />
      </el-col>
      <el-col :span="10">
        <div class="is-flex is-align-items-center">
          <el-input
            v-if="ingredientDish.ingredient.unit.title === 'кг'"
            v-model="ingredientDish.brutto"
            style="width: 90%"
            class="mr-3"
            placeholder="Укажите кол-во ингредиента"
          />
          <el-input
            v-else
            v-model="ingredientDish.count"
            style="width: 90%"
            class="mr-3"
            placeholder="Укажите кол-во ингредиента"
          />
          <span style="font-weight: bold">{{
            ingredientDish.ingredient.unit.title === 'кг'
              ? 'гр.'
              : ingredientDish.ingredient.unit.title
          }}</span>
        </div>
      </el-col>
      <el-col :span="2">
        <el-button type="success" size="small" class="mt-1" icon="el-icon-plus" @click="saveFn" />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_INGREDIENTS } from '@/app/administration/ingredients/shared/state/types/action_types';
import { DATA } from '@/app/administration/ingredients/shared/state/types/getter_types';

export default {
  name: 'AddIngredient',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ingredientDish: {
        ingredient: {
          title: null,
          id: null,
          unit: {
            title: null,
          },
        },
        count: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      ingredients: DATA,
    }),
  },
  methods: {
    ...mapActions({
      fetchIngredients: FETCH_INGREDIENTS,
    }),
    saveFn() {
      this.$emit('add-ingredient', {
        ingredient_id: this.ingredientDish.ingredient.id,
        brutto: this.ingredientDish.brutto,
        netto: this.ingredientDish.brutto,
        count: this.ingredientDish.brutto,
      });
    },
    selectIngredientFn(ingredient) {
      this.ingredientDish.ingredient = ingredient;
    },
    async searchIngredientsFn(queryString, cb) {
      const filter = {};
      if (queryString) {
        filter.title = queryString;
      }
      await this.fetchIngredients({ filter });
      cb(this.ingredients.data);
    },
  },
};
</script>

<template>
  <div class="is-flex is-justify-content-space-around pt-2 pb-2 pl-2 bg-grey">
    <p>
      Калорий: <b>{{ formatedKbju(calories) }}</b> гр
    </p>
    <p>
      Белков: <b>{{ formatedKbju(proteins) }}</b> гр
    </p>
    <p>
      Жиров: <b>{{ formatedKbju(fats) }}</b> гр
    </p>
    <p>
      Углеводов: <b> {{ formatedKbju(carbohydrates) }}</b> гр
    </p>
    <p>
      Масса нетто: <b> {{ formatedNetto(netto) }}</b>
    </p>
  </div>
</template>
<script>
export default {
  name: 'EnergyValue',
  props: {
    calories: {
      type: String,
      default: null,
    },
    proteins: {
      type: String,
      default: null,
    },
    fats: {
      type: String,
      default: null,
    },
    carbohydrates: {
      type: String,
      default: null,
    },
    netto: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    formatedKbju(item) {
      return Number(item) > 1 ? Number(item).toFixed(2) : Number(item).toPrecision(2);
    },
    formatedNetto(item) {
      let netto;
      if (+item) {
        netto = item;
      } else {
        netto = item.substring(0, item.length - 3);
      }

      if (Number(netto) > 1000) {
        const kg = Number(netto) / 1000;
        return `${Number(kg).toFixed(3)} кг`;
      }
      return Number(netto) > 1
        ? `${Number(netto).toFixed(3)} гр`
        : `${Number(netto).toPrecision(2)} гр`;
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-grey {
  background: #f5f7fa;
}
</style>

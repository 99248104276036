<template>
  <div class="column-methods">
    <el-checkbox
      v-model="methods.losses_cleaning_checked"
      @change="changeIngredientSize('losses_cleaning', methods)"
    >
      Отчистка
    </el-checkbox>
    <el-checkbox
      v-model="methods.losses_frying_checked"
      @change="changeIngredientSize('losses_frying', methods)"
    >
      Жарка
    </el-checkbox>
    <el-checkbox
      v-model="methods.losses_cooking_checked"
      @change="changeIngredientSize('losses_cooking', methods)"
    >
      Варка
    </el-checkbox>
    <el-checkbox
      v-model="methods.losses_stew_checked"
      @change="changeIngredientSize('losses_stew', methods)"
    >
      Тушение
    </el-checkbox>
    <el-checkbox
      v-model="methods.losses_baking_checked"
      @change="changeIngredientSize('losses_baking', methods)"
    >
      Запекание
    </el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'ColumnMethods',
  props: {
    methods: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeIngredientSize(action, row) {
      if (row.ingredient.ingredients) {
        this.$emit('selectMethod', {
          semis_id: row.ingredient.id,
          [`${action}_checked`]: row[`${action}_checked`],
          action: 'semis',
        });
        return;
      }
      this.$emit('selectMethod', {
        ingredient_id: row.ingredient.id,
        [`${action}_checked`]: row[`${action}_checked`],
        action: 'ingredient',
      });
    },
  },
};
</script>

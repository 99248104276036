<template>
  <div v-if="!editable" class="brutto">
    {{ formatedBrutto }}
  </div>
  <div v-else>
    <div v-if="unit.id === 1" class="is-flex is-align-items-center">
      <el-input
        v-model="bruttoBase"
        style="width: 80%"
        class="mr-2"
        @change="changeIngredientBrutto"
      />
      гр.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnBrutto',
  props: {
    ingredientId: {
      type: Number,
      default: null,
    },
    brutto: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formatedBrutto() {
      if (this.unit.id === 1) {
        return `${this.brutto} гр.`;
      }
      return this.brutto;
    },
    bruttoBase: {
      get() {
        return this.brutto;
      },
      set(value) {
        this.$emit('update:brutto', value);
      },
    },
  },
  methods: {
    changeIngredientBrutto() {
      this.$emit('changeBrutto', {
        ingredient_id: this.ingredientId,
        brutto: this.brutto.toString().replace(/,/, '.'),
      });
    },
  },
};
</script>

<template>
  <div class="column-netto">
    <div v-if="unit.id === 1" class="is-flex is-align-items-center">
      <el-input
        v-model="nettoBase"
        style="width: 80%"
        class="mr-2"
        @change="changeIngredientNetto"
      />
      гр.
    </div>
    <div v-else class="is-flex is-align-items-center">
      <el-input v-model="count" style="width: 80%" class="mr-2" @change="changeIngredientNetto" />
      {{ unit.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnNetto',
  props: {
    ingredientId: {
      type: Number,
      default: null,
    },
    semis: {
      type: Array,
      default: null,
    },
    netto: {
      type: [String, Number],
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
    unit: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nettoBase: {
      get() {
        return this.netto;
      },
      set(value) {
        this.$emit('update:netto', value);
      },
    },
  },
  methods: {
    changeIngredientNetto() {
      if (this.semis) {
        this.$emit('changeNetto', {
          semis_id: this.ingredientId,
          action: 'semis',
          netto: this.netto.toString().replace(/,/, '.'),
        });
        return;
      }
      this.$emit('changeNetto', {
        ingredient_id: this.ingredientId,
        action: 'ingredient',
        netto: this.netto.toString().replace(/,/, '.'),
      });
    },
  },
};
</script>
